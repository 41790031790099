import React from 'react'

const InputEmail = ({label,name,value,error,changeHandler,placeholder,style}) => {
    return (
        <div className="ticket_wash_note" style={style}>
            <label htmlFor={label}>{label}</label>
            <input type="text" name={name} value={value} onChange={changeHandler} placeholder={ placeholder} autoComplete="off"/>
             <span className="ticket_validation_err">{error}</span>
        </div>
    )
}

export default InputEmail
