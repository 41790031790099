import React,{useState} from 'react'

const Email = ({ img, imageSelect,img_Data}) => {
    return (
        <div className="ticket_img_wash" onClick={imageSelect}>
              <img src={img} alt="post wash document" style={{border:img_Data?"2.5px solid #448ee4":""}}/>
        </div>
    )
}

export default Email
