import React,{useState,useEffect} from 'react'
import Footer from "../../components/layout-components/modal/Footer"
import { Button ,message} from 'antd';
import "./ticketEmail.css"
import Email from "../../components/email/Email"
import API from "../../api"
import InputEmail from '../../components/InputField/InputEmail';
const TicketEmail = (props) => {
    const [post, setPost] = useState(false)
    const [data, setData] = useState(null)
    const [imgDataPost,setImgDataPost]=useState(null)
    const [imgDataPre,setImgDataPre]=useState(null)
    const [state, setState] = useState({ postNote: "", postEmail: "",preNote:"",preEmail:"" })
    const [error, setError] = useState({ postSelectErr: "", postNoteErr: "", postEmailErr: "" })
    const [img, setImg] = useState([])
    // const [loading,setLoading]=useState(true)
    const { shareImage }=API
    useEffect(() => {
        setData(props.data)
        setImgDataPost(props.post)
        setImgDataPre(props.pre)
    }, [props.data])

    const presds=imgDataPre && imgDataPre.filter((item) => {
        return item.name === "Pre Wash Images" ? item : ""
    })
    const sdspre=imgDataPre && imgDataPre.filter((item) => {
        return item.name !== "Pre Wash Images" ? item : ""
    })
    let emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let blankError = "*This field is required!"
    let selectError = "*Please select image!"
    let emailError = "* Please enter the valid email address!"
    let noteError = "*Note must be atleast 4 charactrs long!"

    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({ ...state, [name]: value })
        if(value!==""){
            setError({ postNoteErr: "",postEmailErr: "" })
        }
    }
    const imageSelect = (event, index, path, id) => {
         const singleImg=props.tab===1?[...imgDataPost]:[...imgDataPre]
           singleImg.map((item) => {
             if (item.doc_id === id) {
                 item.show = !item.show;
             }
          })
        props.tab===1?setImgDataPost(singleImg):setImgDataPre(singleImg)

        let allInput = [...img];
        allInput[index] = { ...allInput[index], value: path }
        setImg(allInput)
        if(allInput.length!==0){
            setError({ postSelectErr: "" })
        }
    }
    const submitHandler = () => {
        let sds=[]
        let _img = props.tab===1? imgDataPost.map((item) => {
            if (item.show === true) {
                return item.url
            }
        }):imgDataPre.map((item) => {
            if (item.show === true && item.name === "Pre Wash Images") {
                return item.url
            } else if (item.show === true && item.name !== "Pre Wash Images") {
                  sds.push(item.url)
            }
        })
        let attch = _img.filter((item)=>{
                return item
            })
        if(props.tab===0 && presds.length===0?sds.length===0:attch.length===0){
            setError({ postSelectErr: selectError })
        }else if(state.postNote===""){
            setError({ postNoteErr: blankError })
        }else if (state.postNote.length<4) {
            setError({ postNoteErr:  noteError})
        }else if(state.postEmail===""){
            setError({ postEmailErr: blankError })
        }else if (!emailFormat.test(state.postEmail)) {
            setError({ postEmailErr: emailError})
        }else{
        const sendData = props.tab===1?{
            equipment_no: data?.equipment_no,
            customerName: data?.customer,
            ticketNumber: data?.ticket_ref_id,
            dropOffDate: data?.equipment_dropoff_date,
            equipmentType: data?.equipment_type,
            lastKnown:data?.last_known,
            note: state.postNote,
            postWashDocs: attch,
            to: state.postEmail,
        } : {
            equipment_no: data?.equipment_no,
            customerName: data?.customer,
            ticketNumber: data?.ticket_ref_id,
            dropOffDate: data?.equipment_dropoff_date,
            equipmentType: data?.equipment_type,
            lastKnown:data?.last_known,
            note: state.postNote,
            sdsDocs: sds,
            preWashDocs: attch,
            to: state.postEmail,
        }
        shareImage(sendData)
        .then((res)=>{
            message.success("Mail Sent")
            setPost(false)
            setState({ postNote: "", postEmail: "" })
            setImg([])
            const clearImg=props.tab===1?[...imgDataPost]:[...imgDataPre]
            clearImg.map((item) => {
             if (item.show === true) {
                 item.show = false;
               }
             })
             props.tab===1?setImgDataPost(clearImg):setImgDataPre(clearImg)
		}).catch(e =>{
            message.error('Something went wrong')
		})
    }
    }
    const cancelHandler = () => {
        setPost(false)
        setState({ postNote: "", postEmail: "" })
        setError({ postSelectErr: "", postNoteErr: "", postEmailErr: "" })
        setImg([])
        const clearImg=props.tab===1?[...imgDataPost]:[...imgDataPre]
            clearImg.map((item) => {
             if (item.show === true) {
                 item.show = false;
               }
             })
             props.tab===1?setImgDataPost(clearImg):setImgDataPre(clearImg)
    }
    const postHandler=(e)=>{
        setPost(e)
        setImgDataPost(props.postImage)
        setImgDataPre(props.sds)
    }
    return (
    <Footer 
    custom={true}
    customWidth={props.customWidth}
    className="wash_yard_drawer_btn"
    setVisible={(e)=>postHandler(e)}
    visible={post}
    Button={props.Button}
    buttonCustomWidth={100}
    title="Share Documents/Images"
       >
    {props?.tab===1?
    <div className="ticket_wash_content">
         <ImagesHeader data={data}/>
        <div className="ticket_washpost">
        <div className="ticket_wash_img_mainhead">Select Picture</div>
        <div className="ticket_wash_img_mainhead">Post Wash Images</div>
        <div className="ticket_img_content">
        <div className="ticket_img_cont">
        {imgDataPost && imgDataPost.map((img,index) => {
            return <Email key={index} img={img.url} img_Data={img.show} imageSelect={(e)=>{imageSelect(e,index,img.url,img.doc_id)}}/>
        })}
        </div>
        <span className="ticket_validation_err">{error.postSelectErr}</span>
        </div>
        </div>
        <div className="ticket_wash12">
        <InputEmail label="Note" name="postNote" value={state.postNote} changeHandler={changeHandler} error={error.postNoteErr} placeholder="Enter your note.."/>
        <InputEmail label="Sent to" name="postEmail" value={state.postEmail} changeHandler={changeHandler} error={error.postEmailErr} placeholder="Enter your email"/>
        <Button style={{marginRight:10}} type="primary" onClick={submitHandler}>
                <span className="ml-1">Send</span>
           </Button>
        </div>
    </div>
    :
    <div className="ticket_wash_content">
         <ImagesHeader data={data}/>
        <div className="ticket_washprePost">
        {sdspre && sdspre.length !==0?
        <div className="ticket_wash11">
        <div className="ticket_wash_img_mainhead">Select Picture</div>
        <div className="ticket_wash_img_mainhead">SDS Wash Images</div>
        <div className="ticket_img_content">
        <div className="ticket_img_cont">
        {sdspre && sdspre.map((img,index) => {
            return <Email key={index} img={img.url} img_Data={img.show} imageSelect={(e)=>{imageSelect(e,index,img.url,img.doc_id)}}/>
         })}
        </div>
        <span className="ticket_validation_err">{presds.length===0?error.postSelectErr:""}</span>
        </div>
        </div>:""}
        {presds && presds.length !==0?
        <div className="ticket_wash11">
        <div className="ticket_wash_img_mainhead">{sdspre && sdspre.length ===0?"Select Picture":""}</div>
        <div className="ticket_img_content">
        <div className="ticket_img_cont">
        {presds && presds.map((img,index) => {
            return <Email key={index} img={img.url} img_Data={img.show} imageSelect={(e)=>{imageSelect(e,index,img.url,img.doc_id)}}/>
         })}
        </div>
        <span className="ticket_validation_err">{error.postSelectErr}</span>
        </div>
        </div>:""}
        </div>
        <div className="ticket_wash12">
        <InputEmail label="Details for Bulk Items/Remarks" name="postNote" value={state.postNote} changeHandler={changeHandler} error={error.postNoteErr} placeholder="Enter your note.."/>
        <InputEmail style={{marginLeft:10}} label="Send to" name="postEmail" value={state.postEmail} changeHandler={changeHandler} error={error.postEmailErr} placeholder="Enter your email"/>
        <Button style={{borderTopLeftRadius: 0,borderBottomLeftRadius:0}} type="primary" onClick={submitHandler}>
                <span className="ml-1">Send</span>
           </Button>
        </div>
    </div>
    }
    </Footer>
    )
}

export default TicketEmail;

export const ImagesHeader = ({ data }) => {
    return(
    <div style={{marginBottom:30}}>
      <div className="row">
      <div className="col-4"> <b>Customer Name:</b> {data?.customer}</div>
      <div className="col-4"><b>Equipment No.:</b> {data?.equipment_no}</div>
      {data?.equipment_wash_time && <div className="col-4"><b>Wash Date:</b> {data?.equipment_wash_time}</div>}
      <div className="col-4"><b>Ticket No.:</b> {data?.ticket_ref_id}</div>
      <div className="col-4"><b>Equipment Type:</b> {data?.equipment_type}</div>
      <div className="col-4"><b>Last Contained:</b> {data?.last_known}</div>
     <div className="col-4"><b>Ticket Status:</b> {data?.ticket_status}</div>
     <div className="col-4"><b>Drop-Off Date:</b> {data?.equipment_dropoff_date}</div>
     <div className="col-4"><b>From/Generator:</b> {data?.generator}</div>
    </div>
    </div>
    )
}